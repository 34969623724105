import Vue from 'vue';

window.Vue = Vue;
document.addEventListener('click', function(e) {
    for (let target=e.target; target && target!==this; target=target.parentNode) {
        // loop parent nodes from the target to the delegation node
        if (target.matches('[data-click-url]')) {
            clickToUrl(target);
            break;
        }
    }
});

/**
 * Переход по URL
 * @param element
 */
function clickToUrl(element)
{
    if (!element.getAttribute('data-click-url')) {
        return;
    }

    window.location = element.getAttribute('data-click-url');
}
